<template>
  <div class="descendant-places">
    <h5>Places in {{ placeName }}</h5>

    <div class="table-item table-header">
      <div>{{ meta.total_count }} Places</div>
      <a rel="nofollow" @click="goToSearch">Search within {{ placeName }}</a>
    </div>
    <div
      v-for="place in descendants"
      v-prerender-for="{listName: 'descendants.objects', valueName: 'descendant'}"
      class="table-item"
    >
      <router-link rel="nofollow" :to="getPlaceRoute(place)">{{ place.address_en }} {{ place.name }}</router-link>
    </div>
    <div class="table-item" v-if="meta.next || meta.previous">
      <base-pagination :meta="meta" :show-jump-to="false" @onSwitchPage="onSwitchPage"></base-pagination>
    </div>
  </div>
</template>

<script>
import BasePagination from '@common/elements/BasePagination.vue';
import {getPlaceDetailRoute} from '@common/utils/utils.routes';

export default {
  components: {BasePagination},
  props: {
    place: Object,
    descendants: Array,
    meta: Object,
  },
  computed: {
    placeName() {
      return this.place.address_en;
    },
    withinSearchRoute() {
      return {name: 'search-tool-village', query: {place: this.place.id}};
    },
  },
  methods: {
    getPlaceRoute(place) {
      return getPlaceDetailRoute(place.id, place.pinyin);
    },
    goToSearch() {
      this.$store.commit('setPlaceSearchState', []);
      this.$store.commit('setPlaceSearchMetaState', {});
      this.$router.push(this.withinSearchRoute);
    },
    onSwitchPage(page) {
      this.$emit('onSwitchPage', page);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  background-color: rgba($mcr-dark-tan, 0.08);
}
.table-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid $divider-line-light;

  &:last-child {
    border-bottom: 1px solid $divider-line-light;
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    flex-direction: column;
    justify-content: start;
  }
}
.pagination {
  margin-top: 0;
}
</style>
